<template>
  <div>
    <div style="width: calc(100vw)">
      <van-tabs type="card"  v-model="checkType"  @change="changeType">
<!--        <van-tab title="疫情防控" name="yqfk"></van-tab>-->
        <van-tab title="行业检查" name="aqsc"></van-tab>
        <!--      <van-tab title="疫情防控(旧)" name="yqfk1"></van-tab>-->
      </van-tabs>
    </div>

<!--    <list v-if="checkType=='yqfk'" :venue="venue" ref="checkList"  @viewDetail="viewDetail"></list>-->
    <lists v-if="checkType=='aqsc'"  :venue="venue"  @viewDetail1="viewAqsc"></lists>
    <!--    每条纪录弹窗-->
    <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%' }">
      <div class="model-title">
        <van-icon name="arrow-left" size="28" @click="recordPopup=false" style="top:8px;" />
       <span style="position:relative;top: 2px;left: 10px;">检查详情</span>
      </div>
<!--      <record  v-if="checkType=='yqfk'" :recordData="recordData" ref="record"></record>-->
      <detail v-if="checkType=='aqsc'"  :dict="dict" :recordData="recordData" ref="record"></detail>
    </van-popup>
  </div>
</template>

<script>
import {
  Popup,Icon,Tab, Tabs
} from 'vant';
import record from "../map/components/record"
import detail from "../rectify/qyDetail"
import list from "../map/components/list"
import lists from "../map/components/lists"

export default {
  name: "venueList",
  components:{
    lists,detail,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data(){
    return {
      recordData:{},
      venue:{
        id:GlobalConfig.dev?GlobalConfig.infoId:localStorage.getItem("wljptinfoId")
      },
      recordPopup:false,
      checkType:"aqsc",
      aqscTable:{},
      dict:[]
    }
  },
  mounted(){
    this.getCheckTable();
    this.venue.id = GlobalConfig.dev?GlobalConfig.infoId:localStorage.getItem("wljptinfoId");
    // this.venue.id = "36";
  },
  methods:{
    getCheckTable(){
      this.$http({
        url: "/zuul/cultural/check/table/raw",
        method: "get",
        params:{
          infoType:localStorage.getItem("wljptinfoType")?localStorage.getItem("wljptinfoType"):"all",
          type:"aqsc"
        }
      }).then(res=>{
        if(res.data.success && res.data.data){
          let table = {};
          if(!localStorage.getItem("wljptinfoType")){
            table = res.data.data.length>0?res.data.data[0]:{};
          }else{
            if(res.data.data.length>1){
              res.data.data.map(item=>{
                console.log(item,item.infoType)
                if(("," + localStorage.getItem("wljptinfoType") + ",")==item.infoType){
                  table = item;
                }
              })
            }else{
              table = res.data.data[0]
            }
          }
          this.aqscTable = table;
          this.dict = this.aqscTable.typeList;
          this.venue ={
              dict: this.aqscTable,
              id:this.venue.id
          } ;
        }
      }).finally(f=>{

      })
    },
    viewDetail(data){
      this.recordData = data;
      this.recordPopup = true;
    },
    viewAqsc(data){
      console.log(this.checkType,"viewAqsc",data)
      this.recordData = data.check;
      this.recordPopup = true;
    },
    changeType(index,title){
      console.log(this.checkType);
      if(title=='行业检查'){
        this.venue ={
          dict: this.aqscTable,
          id:this.venue.id
        } ;
        this.dict = this.aqscTable.typeList;
      }
      /*if(title=='疫情防控'){
        this.rectifyType = 'yqfk';
      }else if(title=='行业检查'){
        this.rectifyType = "aqsc";
        this.anscType = {
          show:"全部",
          form:this.aqscTables
        };
      }else if(title=='疫情防控(旧)'){
        this.rectifyType = "yqfk1";
      }else{
        this.rectifyType = "wmcs";
      }*/
    }
  }
}
</script>

<style scoped lang="less">
.model-title{
  position: relative;
  top: -5px;
}
/deep/ .van-tabs--card>.van-tabs__wrap,/deep/ .van-tabs__nav--card{
  height: 45px;
}
 /deep/ .van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
/deep/ .van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
  width: calc(100vw - 0px);
}
 /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
 /deep/.van-tabs__nav--card{
  margin: 0px;
}
</style>
