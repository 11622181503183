<template>
  <div class="record">
    <van-form :label-width="labelWidth" ref="checkForm">
    <div class="form-title">场所防疫</div>
      <div v-for="(obj, key) in formDict1" v-bind:key="key">
        <van-field
            :name="obj.id"
            :label="obj.sort +'.' + obj.name"
            :placeholder="obj.name"
            :rules="[{ required: true, message: '必填' }]"
        >
          <template #input>
            <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
              <van-radio name="1" icon-size="24px">是</van-radio>
              <van-radio name="0" icon-size="24px">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div v-if="fileList[obj.id]">
          <van-field name="remark" rows="3" autosize type="textarea" disabled v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label="" placeholder="检查出的问题及整改要求" />
          <div class="form-label"><span></span>照片列表</div>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </template>
          </van-field>
        </div>
        <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'">
          <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
      </div>

    <div class="form-title">员工防护</div>
      <div v-for="(obj, key) in formDict2" v-bind:key="key">
        <van-field
            :name="obj.id"
            :label="obj.sort +'.' + obj.name"
            :placeholder="obj.name"
            :rules="[{ required: true, message: '必填' }]"
        >
          <template #input>
            <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
              <van-radio name="1" icon-size="24px">是</van-radio>
              <van-radio name="0" icon-size="24px">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div v-if="fileList[obj.id]">
          <van-field name="remark" rows="3" autosize type="textarea" disabled v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label="" placeholder="检查出的问题及整改要求" />
          <div class="form-label"><span></span>照片列表</div>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </template>
          </van-field>
        </div>
      </div>

    <div class="form-title">消费者防护</div>
      <div v-for="(obj, key) in formDict3" v-bind:key="key">
        <van-field
            :name="obj.id"
            :label="obj.sort +'.' + obj.name"
            :placeholder="obj.name"
            :rules="[{ required: true, message: '必填' }]"
        >

          <template #input>
            <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
              <van-radio name="1" icon-size="24px">是</van-radio>
              <van-radio name="0" icon-size="24px">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div v-if="fileList[obj.id]">
          <van-field name="remark" rows="3" autosize type="textarea" disabled v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label="" placeholder="检查出的问题及整改要求" />
          <div class="form-label"><span></span>照片列表</div>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </template>
          </van-field>
        </div>
      </div>

      <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
      <div  v-if="check.checkedSign" style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;">
          <img :src="check.checkedSign" height="80px">
      </div>
      <div v-else style="padding: 8px 16px 0px;">
        无
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人签名</div>
      <div v-if="check.checkerSign" style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;">
          <img :src="check.checkerSign" height="80px">
      </div>
      <div v-else style="padding: 8px 16px 0px;">
        无
      </div>


    <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人</div>
    <van-field
        name="check"
        v-model="check.remark"
        label=""
        disabled
        placeholder="检查人"
    />
      <div class="form-label" style="padding-bottom: 5px;"><span></span>现场照片</div>
      <van-field name="uploader" label="" v-if="pics.length>0">
        <template #input>
          <van-uploader v-model="pics" :max-count="pics.length" :deletable="false"  />
        </template>
      </van-field>
      <div v-else style="padding: 8px 16px 0px;">
        无
      </div>


      <div class="form-label" style="padding-bottom: 15px;"><span></span>检查时间 <span style="float:right;padding-right:20px">{{check.createTime?check.createTime.substr(0,16):""}}</span></div>
    </van-form>
  </div>
</template>
<script>
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      formDict1:{},
      formDict2:{},
      formDict3:{},
      formData:{},
      check:{},
      rectify:{},
      fileList:{},
      pics:[]
    }
  },
  components:{
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
          venue:{},
          check:{}
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        console.log(n,"rechord");

          if(n.check){
            if(n.check.picture){
              let p = n.check.picture.split(",");
              p.map(item=>{
                this.pics.push({
                  url:item,
                  isImage:true
                })
              })
            }
            let _this = this;
            this.check = n.check;
            if(Object.keys(this.formDict3).length>0){
              this.formData = JSON.parse(n.check.content);
            }else{
              setTimeout(function(){
                this.formData = JSON.parse(n.check.content);
              },500);
            }
            if(n.check.problemList.length>0){
              n.check.problemList.map(problem=>{
                /*let pics = [];
                problem.recordFileList.map(pic=>{
                  pics.push({
                    url:pic,
                    isImage: true
                  })
                })
                this.$set(this.fileList,problem.typeId,pics)*/
                let pics = [];
                let rectifyPics=[];
                if(problem.rectifyFileList){
                  problem.rectifyFileList.map(pic=>{
                    rectifyPics.push({
                      url:pic,
                      isImage: true
                    })
                  })
                }

                this.$set(this.rectify,problem.typeId,{
                  des:problem.rectifyResult,
                  id:problem.id,
                  pics:rectifyPics,
                  save:rectifyPics.length>0?true:false,
                  checkResult:problem.checkResult,
                  createTime:problem.createTime,
                  typeId:problem.typeId
                })
                if(problem.recordFileList){
                  problem.recordFileList.map(pic=>{
                    pics.push({
                      url:pic,
                      isImage: true
                    })
                  })
                }

                this.$set(this.fileList,problem.typeId,pics);
              })

            }
          }
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    this.getDictList();
  },
  methods:{
    // 获取检查项
    getDictList(){
      this.$http({
        url: "/zuul/cultural/check/table/" + GlobalConfig.checkTableType,
        method: "get"
      }).then(res=>{
        if(res.data.success){
          this.formDict1 = {};
          this.formDict2 = {};
          this.formDict3 = {};
          res.data.data.typeList.map((item,index)=>{
            if(item){
              if(!this.formData[item.id]){
                this.$set(this.formData,item.id,null)
              }
              if(item.groupName=='场所防疫'){
                this.formDict1[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }else if(item.groupName=='员工防护'){
                this.formDict2[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }else{
                this.formDict3[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }
              /*if(index<8){
                this.formDict1[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }else if(index<12){
                this.formDict2[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }else{
                this.formDict3[item.id] = {
                  name:item.name,
                  sort:index+1,
                  id:item.id
                }
              }*/
            }

          })
          if(this.recordData.check.content){
            this.formData = JSON.parse(this.recordData.check.content);
          }

        }
      })
    },
  }
}
</script>

<style scoped>
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
.record{
  padding-bottom: 20px;
}
.form-title{
  color: rgba(69, 90, 100, 0.6);
  line-height: 45px;
  padding: 5px 16px 0px;
  background: #f7f8fa;
}
.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
/deep/ .van-radio-group--horizontal{
  width: 150px;
}
</style>
